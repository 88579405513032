import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";

import { isEmpty } from "helpers";
import { jobsDictionaryToArray } from "helpers/jobsDictionaryToArray";
import { isEqual } from "lodash";
import { CustomerSimple } from "models/CustomerSimple";
import { Job, Jobs } from "models/Job";
import { defaultPlannerFilter, PlannerFilter, PlannerFilterKeys } from "models/PlannerFilter";
import { EngineerType } from "operations/schema/schema";
import { RootState } from "store";
import { asyncMutations, mutationBuilder } from "./planner.mutations";
import { asyncQueries, queryBuilder } from "./planner.queries";

type PlannerDisplayTypes = "list" | "calendar" | "map";

export interface State {
  selectedJobId: string | undefined;
  jobs: Jobs;
  plannerFilter: PlannerFilter;
  customers: CustomerSimple[];
  engineers: EngineerType[];
  loadingJobs: boolean;
  loadingJob: boolean;
  loadingCustomers: boolean;
  loadingEngineers: boolean;
  loadingAssignTask: boolean;
  loadingRelatedJobs: boolean;
  plannerDisplay: PlannerDisplayTypes;
}

interface Actions extends SliceCaseReducers<State> {
  clearPlannerState: (state: State) => State;
  setPlannerSelectedJob: (
    state: State,
    action: PayloadAction<{ jobId: string | undefined }>
  ) => State;
  setPlannerFilter: (
    state: State,
    action: PayloadAction<{ plannerFilter: PlannerFilter }>
  ) => State;
  resetPlannerFilter: (state: State) => State;
  setPlannerDisplay: (
    state: State,
    action: PayloadAction<{ display: PlannerDisplayTypes }>
  ) => State;
}

interface Selectors {
  selectJobLoaded: (state: RootState) => boolean;
  selectJobs: (state: RootState) => Job[];
  selectLoadingJobs: (state: RootState) => boolean;
  selectPlannerSelectedJobId: (state: RootState) => string | undefined;
  selectPlannerFilter: (state: RootState) => PlannerFilter;
  selectPlannerFilterCount: (state: RootState) => number;
}

export const initialState: State = {
  selectedJobId: undefined,
  jobs: {},
  plannerFilter: { ...defaultPlannerFilter },
  customers: [],
  engineers: [],
  loadingJobs: false,
  loadingJob: false,
  loadingCustomers: false,
  loadingEngineers: false,
  loadingAssignTask: false,
  loadingRelatedJobs: false,
  plannerDisplay: "list",
};

const actions: Actions = {
  clearPlannerState(state) {
    state = { ...initialState };
    return state;
  },
  setPlannerSelectedJob: (state, { payload: { jobId } }) => {
    state.selectedJobId = jobId;
    return state;
  },
  setPlannerFilter(state, { payload: { plannerFilter } }) {
    state.plannerFilter = plannerFilter;
    return state;
  },
  resetPlannerFilter(state) {
    state.plannerFilter = { ...defaultPlannerFilter };
    return state;
  },
  setPlannerDisplay(state, { payload: { display } }) {
    state.plannerDisplay = display;
    return state;
  },
};

const selectors: Selectors = {
  selectJobLoaded({ planner: { selectedJobId, jobs, loadingJob } }) {
    if (selectedJobId && jobs[selectedJobId]) {
      return true;
    }
    return false;
  },
  selectJobs({ planner }) {
    return jobsDictionaryToArray(planner.jobs);
  },
  selectLoadingJobs({ planner: { loadingJobs } }) {
    return !!loadingJobs;
  },
  selectPlannerSelectedJobId({ planner: { selectedJobId } }) {
    return selectedJobId;
  },
  selectPlannerFilter({ planner: { plannerFilter } }) {
    return plannerFilter;
  },
  selectPlannerFilterCount: ({ planner: { plannerFilter } }) => {
    let count = 1; // Start at 1 as we always date filter
    for (const k of PlannerFilterKeys) {
      if (isEqual(defaultPlannerFilter[k], plannerFilter[k]) || isEmpty(plannerFilter[k])) continue;
      count++;
    }
    return count;
  },
};

const storeBase = createSlice<State, Actions>({
  name: "planner",
  initialState,
  reducers: actions,
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    queryBuilder(builder);
    mutationBuilder(builder);
  },
});

export default storeBase.reducer;
export const {
  clearPlannerState,
  setPlannerSelectedJob,
  setPlannerFilter,
  resetPlannerFilter,
  setPlannerDisplay,
} = storeBase.actions;
export const {
  selectJobLoaded,
  selectJobs,
  selectLoadingJobs,
  selectPlannerSelectedJobId,
  selectPlannerFilter,
  selectPlannerFilterCount,
} = selectors;
export const {
  getPlannerJob,
  getPlannerJobs,
  getFiles,
  getNotes,
  getRelatedJobs,
  getVisits,
  getEngineers,
  getCustomers,
} = asyncQueries;

export const { assignTask } = asyncMutations;
