import { Add as AddIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { RefObject, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSignatureCanvas from "react-signature-canvas";

import { unwrapResult } from "@reduxjs/toolkit";
import { AsolviSignoff } from "components/AsolviSignoff";
import { AsolviSwitch } from "components/AsolviSwitch";
import StyledButtonPrimaryMini from "components/StyledButtonPrimaryMini";
import StyledTextField from "components/StyledTextField";
import { EditContactDialog } from "components/job/details/EditContactDialog";
import { isEmpty } from "helpers";
import engineerSettings from "helpers/engineerSettings";
import { ContactType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import {
  getContacts,
  selectSelectedJob,
  selectSelectedJobVisit,
  setEditContactOpen,
  setVisitValue,
} from "store/slices/jobs.store";
import { isFlagEnabled } from "store/slices/user.store";
import { CustomerSatisfactionSurvey } from "./CustomerSatisfactionSurvey";

interface JobSignoffProps {
  canvasReference: RefObject<ReactSignatureCanvas>;
  handleSignature: (added: boolean) => void;
}

export const JobSignoff = (props: JobSignoffProps) => {
  const { canvasReference, handleSignature } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { customerSignerName, skipSignature } = useAppSelector(selectSelectedJobVisit);

  const serviceReportEmailEnabled = engineerSettings.sendMailToCustomer ?? false;
  const customerSignerNameRequired = engineerSettings.requireCustomerSignatureName ?? false;
  const customerSatisfactionEnabled =
    useAppSelector((s) => isFlagEnabled(s, "UpdatedServiceJobEndpoint")) &&
    engineerSettings.customerSatisfactionEnabled;

  const [expandedSignature, setExpandedSignature] = useState(true);
  const [contacts, setContacts] = useState<ContactType[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<ContactType[]>([]);

  const dispatchGetContacts = useCallback(() => {
    dispatch(getContacts())
      .then(unwrapResult)
      .then((contacts) => setContacts(contacts.filter((c) => !isEmpty(c.email))));
  }, [dispatch]);

  useEffect(() => {
    if (serviceReportEmailEnabled) {
      dispatchGetContacts();
    }
  }, [serviceReportEmailEnabled, dispatchGetContacts]);

  const labelText = <FormattedMessage id="visit.signoff.customerSignature" />;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <StyledTextField
          id="signature-name"
          name="customerSignerName"
          label={intl.formatMessage({ id: "job.customer.name" })}
          value={customerSignerName}
          onChange={({ target: { value } }) => {
            dispatch(setVisitValue({ key: "customerSignerName", value }));
          }}
          error={!customerSignerName && customerSignerNameRequired}
        />
        {!customerSignerName && customerSignerNameRequired && (
          <FormHelperText error>
            {intl.formatMessage({ id: "visit.signoff.customerSignerNameRequired" })}
          </FormHelperText>
        )}
      </Grid>
      {serviceReportEmailEnabled && (
        <Grid
          item
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              flex: "1 1 auto",
            }}
          >
            <Autocomplete
              fullWidth
              multiple
              freeSolo
              options={contacts}
              getOptionLabel={(option: string | ContactType) => {
                if (typeof option === "string") return option;
                return option.name === "" ? `${option.email}` : `${option.email} - ${option.name}`;
              }}
              onChange={(_, value) => {
                var currentContacts = (value as ContactType[]).filter((c) => c.email);
                var newItem = value[value.length - 1];

                if (typeof newItem === "string") {
                  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newItem)) {
                    var newContact: ContactType = {
                      id: "",
                      email: newItem,
                      name: "",
                    };
                    currentContacts.push(newContact);
                    setSelectedContacts(currentContacts);
                  }
                } else {
                  setSelectedContacts(currentContacts);
                }
                dispatch(
                  setVisitValue({
                    key: "serviceReportEmail",
                    value: currentContacts.map((c) => c.email).join(";"),
                  })
                );
              }}
              value={selectedContacts}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label={intl.formatMessage({
                    id: "visit.signoff.sendServiceReportTo",
                  })}
                />
              )}
            />
          </div>
          <div
            style={{
              flex: "0 0 auto",
            }}
          >
            <StyledButtonPrimaryMini
              style={{ marginTop: 5.5, marginLeft: 5, minWidth: 45, width: 45, height: 45 }}
              aria-label={intl.formatMessage({ id: "visit.addEditContact" })}
              onClick={() =>
                dispatch(
                  setEditContactOpen({
                    open: true,
                  })
                )
              }
            >
              <AddIcon />
            </StyledButtonPrimaryMini>
          </div>
        </Grid>
      )}
      <Grid item container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item xs={10}>
          <FormattedMessage id="visit.signoff.skipSignature" />
        </Grid>
        <Grid item>
          <AsolviSwitch
            disabled={engineerSettings.requireCustomerSignature}
            checked={skipSignature}
            onChange={({ target: { checked } }) => {
              dispatch(setVisitValue({ key: "skipSignature", value: checked }));
            }}
            name="skipSignature"
          />
        </Grid>
      </Grid>
      {customerSatisfactionEnabled && !skipSignature && <CustomerSatisfactionSurvey />}
      <Grid item sx={{ width: "100%" }}>
        <Accordion disabled={skipSignature} expanded={expandedSignature && !skipSignature}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpandedSignature(!expandedSignature)}
          >
            <Typography>
              <FormattedMessage id="general.signature" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AsolviSignoff
              canvasReference={canvasReference}
              handleSignature={handleSignature}
              labelText={labelText}
              signatureImageData={job.customerSignatureImageData}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <EditContactDialog onSave={() => dispatchGetContacts()} />
    </Grid>
  );
};
