import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  ActionType,
  AreaType,
  ExtraType,
  JobCategoryType,
  JobSymptomType,
  PartType,
  PlannerNodesType,
  SortGroupsType,
  SupplierType,
} from "operations/schema/schema";
import { asyncQueries, queryBuilder } from "./cache.queries";

export type CustomerPartsCache = {
  [x: string]: PartType[];
};
export type AvailablePartsCache = {
  engineerParts: PartType[];
  customerParts: CustomerPartsCache;
  locationalParts: PartType[];
  liveSearchParts: PartType[];
};
export type Loading = {
  prefill: boolean;
  plannerPrefill: boolean;
  availableParts: boolean;
  customerParts: boolean;
  engineerParts: boolean;
  locationalParts: boolean;
  requestableParts: boolean;
  suggestedParts: boolean;
};
export type State = {
  lastLoaded: string | null;
  availableParts: AvailablePartsCache;
  actions: ActionType[];
  areas: AreaType[];
  extras: ExtraType[];
  jobCategories: JobCategoryType[];
  partSortGroups: SortGroupsType;
  plannerNodes: PlannerNodesType[];
  requestableParts: PartType[];
  suggestedParts: {
    [x: string]: string[];
  };
  suppliers: SupplierType[];
  symptoms: JobSymptomType[];
  loading: Loading;
  engineerPartsLastLoaded: string | null;
  requestablePartsLastLoaded: string | null;
};

interface Actions extends SliceCaseReducers<State> {
  setData: <K extends Extract<keyof State, string>>(
    state: State,
    action: PayloadAction<{
      key: K;
      data: State[K];
    }>
  ) => State;
  setLoading: <K extends Extract<keyof Loading, string>>(
    state: State,
    action: PayloadAction<{
      key: K;
      data: Loading[K];
    }>
  ) => State;
}

export const initialState: State = {
  lastLoaded: null,
  availableParts: {
    engineerParts: [],
    customerParts: {},
    locationalParts: [],
    liveSearchParts: [],
  },
  actions: [],
  areas: [],
  extras: [],
  jobCategories: [],
  partSortGroups: {},
  plannerNodes: [],
  requestableParts: [],
  suggestedParts: {},
  suppliers: [],
  symptoms: [],
  engineerPartsLastLoaded: null,
  requestablePartsLastLoaded: null,
  loading: {
    prefill: false,
    plannerPrefill: false,
    availableParts: false,
    customerParts: false,
    engineerParts: false,
    locationalParts: false,
    requestableParts: false,
    suggestedParts: false,
  },
};
const actions: Actions = {
  setData: (state, { payload: { key, data } }) => {
    state[key] = data;
    return state;
  },
  setLoading: (state, { payload: { key, data } }) => {
    state.loading[key] = data;
    return state;
  },
};

const cacheStore = createSlice<State, Actions>({
  name: "cache",
  initialState,
  reducers: actions,
  extraReducers: queryBuilder,
});

export default cacheStore.reducer;
export const { setData, setLoading } = cacheStore.actions;
export const {
  getCachePrefill,
  getPlannerCachePrefill,
  getAvailableParts,
  getPartsEngineer,
  getPartsLocational,
  getRequestableParts,
  getSuggestedParts,
} = asyncQueries;
